<template>
  <div class="container">
    <div class="section-heading section-heading--center" data-aos="fade">
      <h2 class="__title">
        Our Specialty
      </h2>
      <p></p>
    </div>

    <!-- start feature -->
    <div class="feature feature--style-1">
      <div class="__inner">
        <div class="row justify-content-center">
          <!-- start item -->
          <div class="col-3 col-sm-1 col-lg-2">
            <div class="__item  text-center" data-aos="fade" data-aos-delay="100" data-aos-offset="100">
              <i class="__ico">
                <img class="img-fluid" src="~/assets/img/feature_img/corn.png" alt="corn" />
              </i>

              <h5 class="__title">Corn</h5>
            </div>
          </div>
          <!-- end item -->

          <!-- start item -->
          <div class="col-3 col-sm-1 col-lg-2">
            <div class="__item  text-center" data-aos="fade" data-aos-delay="200" data-aos-offset="100">
              <i class="__ico">
                <img class="img-fluid" src="~/assets/img/feature_img/soya2.png" alt="soybeans" />
              </i>

              <h5 class="__title">Soybeans</h5>
            </div>
          </div>
          <!-- end item -->

          <!-- start item -->
          <div class="col-3 col-sm-1 col-lg-2">
            <div class="__item  text-center" data-aos="fade" data-aos-delay="300" data-aos-offset="100">
              <i class="__ico">
                <img class="img-fluid" src="~/assets/img/feature_img/peas.png" alt="peas" />
              </i>

              <h5 class="__title">Peas</h5>
            </div>
          </div>
          <!-- end item -->

        </div>
      </div>
    </div>
    <!-- end feature -->
  </div>
</template>

<script>
export default {
  name: 'Specialties'
}
</script>
