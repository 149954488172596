<template>
  <!-- start main -->
  <main role="main">
    <!-- start section -->
    <section class="section">
      <Specialties />
    </section>
    <!-- end section -->
  </main>
  <!-- end main -->
</template>

<script>
import Specialties from '../components/Specialties'

export default {
  components: {
    Specialties
  }
}
</script>

<style lang="scss" src="~/assets/style/critical.scss/"></style>
<style lang="scss" src="~/assets/style/style.scss" />
